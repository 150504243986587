// ------------------------------------------------------------ STYLE MODULE IMPORTS

@import './typography';
@import './transitions';
// @import './helpers';

// ------------------------------------------------------------ GENERAL STYLES

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main.main {
  position: relative;
  margin: 0;
  padding: 0 0 10px 0;
  // height: 100%;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}

.fullWidth_parent {
  /* width: 100vw !important;
  transform: translateX(calc((100vw - 640px) * -0.5)); */
  // width: 10000px !important;
  // transform: translateX(calc((10000px - 592px) * -0.5));

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  .fullWidth_child {
    width: 100%;
    max-width: 592px;
    margin: 0 auto;
  }

  @media screen and (max-width: 650px) {
    width: calc(100% + 25px + 25px) !important;
    transform: translateX(-25px);
    padding: 35px 25px;

    .fullWidth_child {
      padding: 0 25px;
    }
  }
}

// ------------------------------------------------------------ HIDE RECAPTCHA BADGE

.grecaptcha-badge {
  visibility: hidden;
}

// ------------------------------------------------------------ SUMMARY TABLE HELPERS

.summaryTable {
  border-spacing: 0 1px !important;

  &:not(.noNegativeMargin) {
    margin-bottom: -1px !important;
  }

  th {
    padding-bottom: 10px;
    border-bottom: 3px solid black;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  td,
  th {
    padding: 10px 7px;
  }

  .quantity {
    font-family: "Mulish ExtraBold";
  }
}

.noRowColours {
  background-color: transparent !important;

  tr {
    background-color: transparent !important;
  }
}

// ------------------------------------------------------------ COLUMNS

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 11px;
  grid-gap: 11px;
  margin-bottom: -10px;

  .SelectInput {
    display: grid;
    
    // label {
    //   margin-top: 20px;
    // }
  }
}